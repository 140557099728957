import { useRef } from 'react'
import { useParams } from 'react-router-dom'

import {
  Grid,
  GridItem,
  Box,
  SimpleGrid,
  Image,
  Text,
  useMediaQuery,
  Center,
  // useBreakpointValue,
} from '@chakra-ui/react'

import PrimaryTile from '../../elements/building/dashboard/primaryTile'
import InfoTile from '../../elements/building/dashboard/infoTile'
import FeedbackTile from '../../elements/building/dashboard/feedbackTile'
import { mediaQueries } from '../../utils/mediaQueries/mediaQueries'
import OptiTwinLogoBlack from '../../assets/logo-black.svg'
import BalanceMeter from '../../elements/balanceMeter/balanceMeter'
import { CardData, DashboardDataType } from './dashboardWrapper'
// import SpeedoMeter from '../../elements/speedoMeter/speedoMeter'

type MainDashboardProps = {
  dashboardData: DashboardDataType
}

const MainDashboard = ({ dashboardData }: MainDashboardProps) => {
  const params = useParams()
  const [is4k, is1440p] = useMediaQuery(mediaQueries)
  const buildingSlug: string = params['buildingSlug'] ?? 'unknown building'
  const primaryData: CardData[] = dashboardData?.primary
  const titleRef = useRef<HTMLDivElement>(null)
  const parentRef = useRef<HTMLDivElement>(null)
  const padding = is4k ? 90 : 30
  const titleMb = is4k ? 50 : 20
  const occupiedHeight =
    (titleRef.current?.getBoundingClientRect().height ?? 0) +
    padding +
    titleMb +
    2 // padding
  const percent =
    (dashboardData?.currentConsumption / dashboardData?.currentGeneration) * 100
  const consumed = dashboardData?.currentConsumption,
    generated = dashboardData?.currentGeneration,
    difference = consumed - generated,
    seeSawText =
      difference === 0 ? 'zero' : difference < 0 ? 'positive' : 'negative'
  // const speedoFontSize = useBreakpointValue({
  //   md: '2.4vw',
  //   lg: '1vw',
  // })
  const lineColor =
    percent > 95
      ? 'rgb(123, 164, 8)'
      : percent > 80
        ? 'rgb(237,181,24)'
        : 'rgb(242, 37, 62)'
  return (
    <SimpleGrid
      p={`${padding / 2}px`}
      bg={'dashboard.primaryColor'}
      ref={parentRef}
    >
      <Box
        ref={titleRef}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={{ base: '20px', '2xl': `${titleMb}px` }}
      >
        <Image
          src={OptiTwinLogoBlack}
          alt={'logo.png'}
          maxW={{
            base: '90px',
            md: '150px',
            xl: '200px',
            '2xl': is1440p ? '250px' : is4k ? '350px' : '200px',
          }}
        />
        <Text color={'dashboard.fontColor'} fontSize={'2.89vw'}>
          {dashboardData?.buildingName}
        </Text>
        <Image
          src={
            dashboardData?.occupierLogo
              ? dashboardData?.occupierLogo
              : OptiTwinLogoBlack
          }
          maxW={{
            base: '90px',
            md: '150px',
            xl: '200px',
            '2xl': is1440p ? '250px' : is4k ? '350px' : '200px',
          }}
          maxH={{
            base: 100,
            '2xl': is4k ? '170px' : 100,
          }}
          alt={'occupier.png'}
        />
      </Box>
      <Box>
        <Grid
          templateAreas={{
            base: `
            "primary1"
            "primary2"
            "primary3"
            "balance"
            "feedback1"
            "feedback2"
            "info"
            `,
            lg: `
        "primary1 primary1 primary2 primary2 primary3 primary3 balance balance"
        "primary1 primary1 primary2 primary2 primary3 primary3 balance balance"
        "primary1 primary1 primary2 primary2 primary3 primary3 balance balance"
        "primary1 primary1 primary2 primary2 primary3 primary3 balance balance"
        "primary1 primary1 primary2 primary2 primary3 primary3 balance balance"
        "feedback1 feedback1 feedback1 feedback2 feedback2 feedback2 info info"`,
          }}
          gridTemplateRows={{
            base: 'repeat(4,auto) repeat(3, 1fr)',
            md: 'repeat(4,auto) repeat(3, 150px)',
            lg: 'repeat(5,1fr) auto',
          }}
          gridTemplateColumns={{
            base: '1fr',
            md: 'auto',
            lg: 'repeat(8,1fr)',
          }}
          gap={{
            base: '5px',
            md: '15px',
            lg: '10px',
            '2xl': is4k ? '20px' : '10px',
          }}
          p={{
            base: '5px',
            md: '15px',
            lg: '10px',
            '2xl': is4k ? '20px' : '10px',
          }}
          borderWidth={'1px'}
          borderColor={'green.500'}
          minHeight={{ lg: `calc(100vh - ${occupiedHeight}px)` }}
        >
          {primaryData?.map((datum: CardData, i: number) => (
            <GridItem
              bg="dashboard.primaryColor"
              area={`primary${i + 1}`}
              key={i}
              filter={'brightness(0.95)'}
              p={{
                base: '10px',
                md: '20px',
                lg: '15px',
                '2xl': is1440p ? '50px' : is4k ? '60px' : '35px',
              }}
            >
              <PrimaryTile
                type={datum.type}
                measurement={datum.measurement}
                target={datum.target}
                comment={datum.comment}
                comparison={datum.comparison}
                period={dashboardData.period}
              />
            </GridItem>
          ))}
          <GridItem
            p={{
              base: '20px',
              lg: '15px',
              '2xl': is1440p ? '50px' : is4k ? '60px' : '35px',
            }}
            bg="dashboard.primaryColor"
            area={'balance'}
            filter={'brightness(0.95)'}
          >
            <Center
              flexDirection={{ md: 'row', lg: 'column' }}
              height={'100%'}
              p={{ base: '10px', md: 'none' }}
            >
              <Center
                w={'100%'}
                flexBasis={{ base: '50%', lg: '61%' }}
                flexShrink={0}
                paddingRight={{ base: '10px', md: '30px', lg: 'unset' }}
              >
                <BalanceMeter
                  consumed={+dashboardData?.currentConsumption.toFixed(2)}
                  generated={+dashboardData?.currentGeneration.toFixed(2)}
                  colors={dashboardData.dashboardColors}
                />
              </Center>
              <Box
                borderTop={{
                  lg: `1px solid ${lineColor}`,
                  '2xl': is4k
                    ? `3px solid ${lineColor}`
                    : `1px solid ${lineColor}`,
                }}
                borderLeft={{
                  base: `1px solid ${lineColor}`,
                  md: `2px solid ${lineColor}`,
                  lg: 'none',
                }}
                p={{ base: '15px', md: '30px', lg: 'unset' }}
                color={'dashboard.fontColor'}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-evenly'}
                mt={{ lg: 5 }}
                lineHeight={'initial'}
                height={{ base: '100%', lg: '40%' }}
                minH={{ base: '100%', lg: '40%' }}
                maxH={{ base: '100%', lg: '40%' }}
              >
                <Text
                  fontSize={{
                    base: '2.9vw',
                    md: '2.7vw',
                    lg: '1.3vw',
                  }}
                  textAlign={'justify'}
                  m={'20px 0'}
                >
                  The net overall result is {seeSawText}, as we have consumed{' '}
                  {consumed}&thinsp;kWh of energy since yesterday compared to
                  the {generated}
                  &thinsp;kWh of energy generated.
                </Text>
              </Box>
            </Center>

            {/* <Box h={{ base: '220px', md: '100%', lg: '40%' }} width={'100%'}>
              {false ? (
                <SpeedoMeter
                  fontSize={speedoFontSize}
                  speedometerValues={dashboardData?.speedometerValues}
                />
              ) : (
                <BalanceMeter consumed={150} generated={100} />
              )}
            </Box> */}
            {/* </Center> */}
          </GridItem>
          <GridItem
            bg="dashboard.secondaryColor"
            area={'info'}
            p={{ base: '15px', md: '20px', lg: '10px' }}
          >
            <InfoTile
              qrCode={`https://live.opti-twin.co.uk/building/${buildingSlug}/dashboard`}
            />
          </GridItem>
          <GridItem
            bg="dashboard.primaryColor"
            area={'feedback1'}
            filter={'brightness(1.1)'}
          >
            <FeedbackTile
              contentSelection={'tile-1'}
              message={dashboardData?.infoMessage}
            />
          </GridItem>
          <GridItem
            bg="dashboard.primaryColor"
            area={'feedback2'}
            filter={'brightness(1.1)'}
          >
            <FeedbackTile
              contentSelection={'tile-2'}
              message={dashboardData?.tryMessage}
            />
          </GridItem>
        </Grid>
      </Box>
    </SimpleGrid>
  )
}

export default MainDashboard
